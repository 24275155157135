import React, { Component } from 'react'
import { AuthUserContext, withAuthorization } from '../Session';
/*import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';*/
import { Select } from 'antd';
const { Option } = Select;


class AccountPage extends Component {

    handleChange = (value, authUser) => {
        var setStatus = this.props.firebase.subscribeToUpdates('users/' + authUser[0].uid);
        var updates = {};
        updates['currentServer'] = value;

        setStatus.update(updates)
        console.log(`selected ${value}`);
    }

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    //console.log(authUser[1])
                    <div>
                        <h1>Account: {authUser[0].email}</h1>
                        
                        {(authUser[1] != null) ? (<div>Current view: <Select defaultValue={authUser[1].currentServer} style={{ width: 120 }} onChange={(value) => this.handleChange(value, authUser)}>
                            <Option value="tbint">TBInt</Option>
                            <Option value="gorilla">Gorilla</Option>
                            <Option value="ff">FF</Option>
                        </Select></div>) : "Loading..."}

                    </div>
                )
                }
            </AuthUserContext.Consumer>
        )
    };
}
const condition = authUser => !!authUser;
export default withAuthorization(condition)(AccountPage);
/*<PasswordForgetForm />
                        <PasswordChangeForm />*/