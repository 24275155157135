import React, { Component } from 'react';
import { AuthUserContext, withAuthorization } from '../Session';
import Weather from "./Timer"

class HomePage extends Component {
    componentDidMount() {
    }


    //renderProduct = ({ product_id, name }) => <div key={product_id}>{name}</div>

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => {

                    return (<div className="HomePage"><Weather currentTime={this.props.currentTime} authUser={authUser} /></div>)

                }}
            </AuthUserContext.Consumer>
        );
    }
}

/*{products.map(this.renderProduct)}
                <div>
                    <input
                        value={product.name}
                        onChange={e => this.setState({ product: { ...product, name: e.target.value } })} />
                    <input
                        value={product.price}
                        onChange={e => this.setState({ product: { ...product, price: e.target.value } })} />
                    <button onClick={this.addProduct}>Add product</button>
                </div>
                */

const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomePage);